@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
$font: Ubuntu;
$fixed-header-height: 40px;
$quilt-header-height: 140px;
$header-height: 180px;
$header-height-mobile: 100px;

html,body {
  font-family:$font;
  background-color: $white;
  font-size: 16px;
  color: #4C4C4C;
  margin: 0;
  padding: 0;
}

a:target {
  margin-top: -200px !important;
  padding-top: 200px !important;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: -160px !important;
    padding-top: 160px !important;
  }
}
html.logged-in a:target {
  margin-top: -250px !important;
  padding-top: 250px !important;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: -210px !important;
    padding-top: 210px !important;
  }
}

.cookie-message-container {
  background: rgba(32,32,32,0.9);
  padding: 10px 20px;
  color: white;
}

.fixed_header {
  position: fixed;
  z-index: 30;
  width: 100%;
  height: $fixed-header-height;
  background: theme-color("primary");
  color: white;
  font-size: 14px;

  a {
    color: white;
    text-decoration: none;
  }

  .fixed_nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;

    div.faculty_name {
      font-size: 16px;
      padding: .5rem 1rem;
    }

    ul.nav {
      .nav-item {
        display: block;
        padding: .5rem 1rem;

        img {
          margin: 0 5px;
        }
      }

      .multilang {
        background-image: url('../../../icons/earth.svg');
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 22px;
        margin-left: 10px;
        height: 40px;

        .w3c-lang-name {
          display: block;
          text-transform: uppercase;
        }

        .full-lang-name {
          display: none;
        }
      }
      .autocomplete_search {
        height: 40px;

        #unibox-suggest-box {
          a {
            padding: 10px;
          }
          .search_icon, .search_txt {
            display: inline-block;
            padding: 0;
            padding-right: 10px;
          }
          h4 {
            text-align: left;
            margin: 0 !important;
            padding: 10px;
            background: #f8f8f8;
          }

        }

      }
    }
  }

  .quilt_nav {
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    @media (min-width: map-get($grid-breakpoints, xl)) {
      height: $quilt-header-height;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      .logo {
        display: flex;
        align-items: center;
        margin: 0 100px;
        img {
          height: 100px;
        }
        .name, .hamburger {
          display: none;
        }
      }
      .horizontal_nav_div {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .mobile_info {
        display: none !important;
      }

    }
    @media (max-width: map-get($grid-breakpoints, xl)) {
      height: $header-height-mobile;
      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: theme-color("primary");
        font-size: 16px;
        a {
          color: theme-color("primary");
        }
        img {
          height: 80px;
          margin: 10px;
        }
        .hamburger img {
          height: 20px;
          margin: 15px;
          cursor: pointer;
        }
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      background: white;
      #horizontal_nav_div {
        background: white;
        display: none;
        flex-direction: column;
      }
      .mobile_info {
        background-color: theme-color("primary");
        padding: 20px;
        text-align: center;
        & > div {
          display: inline-block;
          margin-left: auto ;
          margin-right: auto;
          text-align: left;
          position: relative;
          & > div {
            display: inline-block;
            padding: 5px 10px;

            .imgcontainer {
              display: inline-block;
              text-align: center;
              width: 40px;
            }
          }
          .accessibility_mobile {
            position: absolute;
            top: 0;
            right: 30px;
            #accessibility_opener {
              height: 35px;
              width: 35px;
            }
          }
        }
      }
      .multilang {
        padding: 10px;
        padding-right: 30px;
        margin: 0px;
        text-align: center;

        .w3c-lang-name {
          background-image: url('../../../icons/earth.svg');
          background-position: left center;
          background-repeat: no-repeat;
          display: block;
          text-transform: uppercase;
          width: 70px;
          margin-left: auto;
          margin-right: auto;
        }

        .full-lang-name {
          display: none;
        }
      }

      .autocomplete_search {

      }

    }
  }
}

.portlet_nav {
  .cms_module_title {
    display: none;
  }
  .left_menu_hamburger {
    display: none;
  }
}

nav.main_nav {
  border: 1px solid #d5d5d5;
  border-top: 0;
  color: #4C4C4C;
  a { color: #4C4C4C;}
  .submenu {
    padding: 0 !important;
  }
  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    border-top: 1px solid #d5d5d5;
    font-size: 15px;
    font-weight: bold;
    .nav-link {
      padding: 10px;
      flex: 1;
    }
    .links {
      display: flex;
      position: relative;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .collapse-link, .collapse-nochildren {
      display: inline-block;
      padding: 10px;
      content: url('../../../icons/arrow-dropdown-closed.svg');
      transform: rotate(90deg);
      transition-duration: 0.2s;
      i {
        display: none;
      }
    }

    .collapse-link.collapsed {
      transform: rotate(0deg);
    }

    .collapse-nochildren {
      content: " " !important;
      width: 28px;
    }

  }

  .submenu::before {
    display: block;
    height: 1px;
    width: 90%;
    background: #d5d5d5;
    content: " ";
    margin: 0 5%;
  }
  .submenu ul {
    li {
      border: 0;
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: left;
      &:hover {
        background-color: #f8f8f8;
      }
    }

    .nav-link {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      padding-left: 0;
    }

    .submenu .nav-link {
      font-size: 15px;
      text-transform: none;
    }

    .nav-link.current_page {
      color: theme-color("primary");
    }

    .collapse-link, .collapse-nochildren {
      padding: 10px;
      content: url('../../../icons/arrow-submenu.svg');
      transform: rotate(90deg);
      transition-duration: 0.2s;
    }

    .collapse-link.collapsed {
      transform: rotate(0deg);
    }

    .collapse-nochildren {
      content: " ";
    }
    .submenu::before {
      display: none;
    }

    .submenu ul {
      .links:hover {
        background-color: #f0f0f0;
      }
      background-color: rgba(0,0,0,0.03);
      li {
        border-top: 1px solid #d5d5d5;
      }
      border-bottom: 1px solid #d5d5d5;
    }
  }

  .public_page::after, .private_page::after {
    position: absolute;
    top: 20px;
    left: 1px;
    height: 4px;
    width: 4px;
    content: " ";
  }
  .public_page.present_page::after {
    border-left: 4px solid theme-color("primary");
  }
  .private_page.present_page::after {
    border-left: 4px solid red;
  }
  .public_page.not_present_page::after {
    border-left: 2px dotted theme-color("primary");
    top: 15px;
    height: 12px;
  }
  .private_page.not_present_page::after {
    border-left: 2px dotted red;
    top: 15px;
    height: 12px;
  }
}

#cms_area_left nav.main_nav {
  .public_page::after, .private_page::after {
    right: 2px !important;
    left: auto !important;
  }
}
#cms_area_horizontal_nav .submenu {
  .public_page::after, .private_page::after {
    right: 10px !important;
    left: auto !important;
  }
}



#cms_area_left nav.main_nav {

  & > ul > li > .links:hover {
    background-color: #f8f8f8;
  }
}

#cms_area_left .portlet_nav {
  margin-bottom: 30px;
  @media (max-width: map-get($grid-breakpoints, lg)) {
    .left_menu_hamburger.use_dropdown {
      display: inline-block;
      border: 1px solid #d5d5d5;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;

      img.rotated {
        transform: rotate(90deg);
      }
    }
    .main_nav.use_dropdown {
      display: none;
      margin-top: -1px;
    }
  }
  nav.main_nav.main_site_menu {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      & > ul > .nav-item:not(.active) {
        display: none;
      }
      & > ul > li > div.links > a.collapse-link {
        transition-duration: 0s !important;
      }
    }
  }
}



#cms_area_horizontal_nav {
  width: 100%;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    height: 80vh;
    overflow: scroll;
  }
}
#cms_area_horizontal_nav nav.main_nav {
  .upisi {
    @extend .rounded;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: 32px;
    margin-right: 32px;

    background: green;
    color: white;
    font-weight: 700;

  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    display: flex;
    flex-direction: row !important;
    border: 0 !important;
    & > ul {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      border: 0 !important;
      & > li > div.links > a.collapse-link {
        content: url('../../../icons/arrow-submenu.svg');
        margin-left: -10px;
        transition-duration: 0.05s;
      }
    }
    & > ul > li > div > a {
      color: black;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
    li {
      border: 0 !important;
    }
    & > ul > li > .submenu {
      position: absolute;
      background: white;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
      padding: 5px 0 !important;
      div.links {
        padding-left: 5px;
        padding-right: 30px;
      }

    }
    .submenu::before {
      display: none;
    }
    .submenu ul {
      border-bottom: 0 !important;
    }

  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    background: white;
    text-transform: uppercase;
    .upisi {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      flex: 0;
    }
  }
}



.scrollable_content {
  padding-top: $header-height;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding-top: $header-height-mobile;
  }
  background: white;

}

#area_middle {
  padding-bottom: 40px;
}

.news_show_headlines_render_articles {
  .news_show_headlines_article {
    padding: 0 !important;
  }
  .card {
    height: 375px;
  }
  .card-body {
    padding: 25px;
    padding-left: 30px;
    padding-top: 0px;
    font-size: 17px;
  }
  .card-img-top {
    width: 100%;
    height: 180px !important;
    object-fit: cover !important;
  }
  .card-title {
    height: 90px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    padding-left: 15px;
    border-left: 3px solid theme-color("secondary");
    margin: 25px;
    margin-left: 30px;
  }
  .card-content {
    height: 200px;
    padding-right: 42px;
  }
  .card-footer {
    font-size: 12px;
    color: #a1a1a1;
    text-transform: uppercase;
    background: transparent;
    border: none;
  }
  .cover_link {
    transition-duration: 0.5s;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .cover_link:hover {
    background: rgba(0,0,0,0.04);
  }
}

#area_news {
  .portlet_news_show_headlines {
    a, .card-title {
      color: white !important;
    }
  }
}

.portlet_news {
  .news_articles {
    .card {
      margin: 0 !important;
      padding: 30px;
      .card-body {
        border-left: 3px solid theme-color("secondary");
      }
    }
    .right {
      .title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;

        a {
          color: #4a4a4a;
        }
      }
    }

    .news_options {
      text-align: right;
      margin-right: 15px;
      padding: 0 !important;
    }
  }
  .date {
    color: #a1a1a1;
    font-size: 12px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .news_article_full {
    .content {
      margin: 30px 0;
    }
    .pic {
      text-align: center;
      margin: 30px 0;
    }
  }
  .news_admin_links {
    a {
      margin: 10px;
    }
  }
}

.portlet_portfolio  {
  .cms_module_title {
    display: none;
  }
  .portfolio_image {
    @extend .img-fluid;
    margin-top: 15px;
  }
  .portfolio-edit {
    .input_text {
      @extend .row;
    }
    label {
      text-align: left !important;
      justify-content: left !important;
      padding: 20px 0;
    }
  }
  .admin_toolbox {
    margin: 15px 0;
  }
  h3, h4 {
    color: black;
    text-transform: uppercase;
    border-left: 4px solid theme-color("secondary");
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    background: transparent;
  }
  #person_name {
    margin-top: 0 !important;
  }
  div.cms_table_row_1 {
    background: transparent;
  }
  .more_info > div > div {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  .basic_info div.row {
    padding: 10px 0;
    p {
      margin: 0;
    }
  }
  .zvanje, .funkcija {
    strong {
      padding-right: 10px;
    }
  }
}

.portlet_repository {
  div.content {
    color: gray !important;
    font-style: italic;
  }
}

.portlet_search {
  div.search_div {
    margin: 15px;
  }
  .search_result {
    margin: 20px 0;
  }
}


.portlet_predmet_info {
  td {
    padding-right: 15px;
  }
}

footer {
  background: #333333;
  color: white;
  font-size: 14px;
  min-height: 300px;
  a {
    color: #FFD037;
  }
  a:hover {
    color: white;
    text-decoration: none;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #FFD037;
  }
  h4 {
    font-size: 16px;
    img {
      position: absolute;
      left: -80px;
      top: -30px;
    }
  }
  & > div {
    padding: 40px;
  }
  .social {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    a {
      padding-left: 20px;
    }
    img {
      padding: 5px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    .row > div {
      margin: 15px 0;
    }
    .social {
      text-align: left;
      a {
        padding-left: 0;
        padding-right: 20px;
      }
      img {
        padding-right: 10px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    .row > div {
      text-align: center;
    }
    h4 img {
      left: 0;
    }
    .social {
      text-align: left;
      a {
        padding-left: 10px;
        padding-right: 10px;
      }
      img {
        padding: 5px;
      }
    }
  }


}

.bodycms_onecol .portlet_carousel {
  margin-bottom: 32px;

  .carousel {
    min-height: 720px;
    background-size: cover;
    margin: 0 !important;
    padding: 0 !important;
    background: white;

    .carousel-inner img {
      object-fit: cover;
      height: 720px;
      min-width: 100%;
    }

    .carousel-caption::before, .carousel-caption::after {
      height: 120px;
      width: 120px;
      background-color: rgba(255,255,255,0.9);
      content: " ";
      position: absolute;
      top: -120px;
      left: -120px;
    }
    .carousel-caption::after {
      top: 480px;
      left: 480px;
    }
    .carousel-caption {
      a {
        color: white !important;
        &:hover {
          text-decoration: none;
        }
      }
      position: absolute;
      top: 120px;
      left: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 480px;
      width: 480px;
      background-color: rgba(43, 119, 200, 0.9);
      text-transform: uppercase;
      h1 {
        font-size: 52px;
        font-weight: 700;
      }
      p {
        font-size: 36px;
        font-weight: 300;
      }
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
      min-height: 480px;
      .carousel-caption::before, .carousel-caption::after {
        display: none;
      }
      .carousel-caption {
        top: 0;
        left: 0;
        bottom: 0;
      }
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      min-height: 400px;
      .carousel-caption {
        height: 400px;
        width: 400px;
        h1 {
          font-size: 46px;
        }
        p {
          font-size: 32px;
        }
      }
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      min-height: 300px;
      .carousel-caption {
        height: 300px;
        width: 300px;
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 24px;
          padding: 0 20px;
        }
      }
    }
  }
}

.bodycms_twocol_left .portlet_carousel {
  margin-bottom: 32px;

  .carousel {
    min-height: 400px;
    background-size: cover;
    margin: 0 !important;
    padding: 0 !important;
    background: white;

    .carousel-inner img {
      object-fit: cover;
      height: 400px;
      min-width: 100%;
    }

    .carousel-caption {
      color: white;
      top: 35% !important;

      a {
        color: white;
        text-decoration: none;
      }

      h1 {
        font-size: 72px;
        font-weight: 700;
        text-transform: uppercase;
        border-left: 8px solid theme-color("secondary");
        text-align: left;
        padding-left: 40px;
      }
      @media (max-width: map-get($grid-breakpoints, xl)) {
        h1 {
          font-size: 64px;
          border-left: 7px solid theme-color("secondary");
        }
      }

      @media (max-width: map-get($grid-breakpoints, lg)) {
        h1 {
          font-size: 56px;
          border-left: 7px solid theme-color("secondary");
        }
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        h1 {
          font-size: 48px;
          border-left: 6px solid theme-color("secondary");
        }
      }

      @media (max-width: map-get($grid-breakpoints, sm)) {
        h1 {
          font-size: 42px;
          border-left: 6px solid theme-color("secondary");
        }
      }

    }
  }
}

.carousel_title {
  display: none;
  height: 250px;
  align-items: center;
  background: #1B5CA0;
  color: white;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  p {
    border-left: 6px solid theme-color("secondary");
    padding-left: 30px;
  }


  @media (max-width: map-get($grid-breakpoints, xl)) {
    font-size: 40px;
    height: 200px;
    p {
      border-left: 4px solid theme-color("secondary");
      padding-left: 20px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 32px;
    height: 160px;
    p {
      border-left: 3px solid theme-color("secondary");
      padding-left: 15px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 24px;
    height: 120px;
    p {
      border-left: 2px solid theme-color("secondary");
      padding-left: 10px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 20px;
    height: 100px;
    p {
      border-left: 2px solid theme-color("secondary");
      padding-left: 10px;
    }
  }
}
.cms_module_title {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1c1c1c !important;
  border-left: 4px solid theme-color("secondary");
  text-align: left;
  padding-left: 20px;
  margin-bottom: 40px;
  margin-top: 5px;
  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 32px;
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 28px;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 24px;
  }
  @media (max-width: map-get($grid-breakpoints, xs)) {
    font-size: 20px;
  }
}

.portlet_spacer {
  height: 40px;
}

.portlet_content, portlet_predmet_info, .portlet_djelatnici, .portlet_portfolio, .portlet_repository, .portlet_news, .portlet_news_show_headlines {
  margin-bottom: 30px;
}
.portlet_subscription {
  margin: 30px 0;
  .btn {
    margin: 20px 0;
  }
}

.portlet_content {


  .highlight1, .highlight2, .highlight3 {
    .cover_link {
      transition-duration: 0.5s;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
      color: transparent;
    }
    .cover_link:hover {
      background: rgba(0,0,0,0.04);
    }

    font-size: 22px;
    border: 1px solid #d5d5d5;
    padding: 40px;
    padding-top: 0;
    margin-bottom: 20px;
    & > div {
      padding-left: 40px;
    }
    &::before {
      display: inline-block;
      height: 90px;
      width: 90px;
      text-align: center;
      padding-top: 13px;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 36px;
      font-weight: 700;
      text-transform: uppercase;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
      height: 100%;
    }
    @media (max-width: map-get($grid-breakpoints, xl)) {
      h2 {
        font-size: 28px;
      }
      font-size: 18px;
      padding: 20px;
      & > div {
        padding-left: 20px;
      }

    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      h2 {
        font-size: 24px;
      }
      font-size: 16px;
    }

  }
  .highlight1 {
    & > div {
      border-left: 3px solid #2B77C8;
    }
    &::before {
      content: url('../../../icons/knjiga.svg');
      background: #2B77C8;
    }
  }

  .highlight2 {
    & > div {
      border-left: 3px solid #047E3B;
    }
    &::before {
      content: url('../../../icons/sova.svg');
      background: #047E3B;
    }
  }

  .highlight3 {
    & > div {
      border-left: 3px solid #F8C51E;
    }
    &::before {
      content: url('../../../icons/kapa.svg');
      background: #F8C51E;
    }
  }
}


.cms_module {
  .ui-widget {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .ui-tabs {
    .ui-tabs-panel {
      border: 1px solid #d5d5d5;
      border-top: 0;
      padding: 50px;
    }
    .ui-tabs-nav {
      border: none;
      border-bottom: 1px solid #d5d5d5;
      margin: 0 !important;
      padding: 0 !important;
      li {
        border: 1px solid #d5d5d5;
        padding: 0px 30px;
        margin: 0;
        margin-bottom: -1px;
        margin-top: 4px;

        a {
          border: none;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          color: #4c4c4c;
          background-color: transparent !important;
          padding: 15px;
        }
        a:hover {
          color: #4c4c4c;
          text-decoration: none;
          background-color: transparent !important;
        }

        &.ui-state-default {
          background-color: #fafafa !important;
          &:hover {
            background-color: #fafafa !important;
          }
        }

        &.ui-state-active {
          a {
            font-weight: 700;
            border-bottom: 3px solid  theme-color("secondary");
          }
          border-bottom: 0;
          background-color: white !important;
          &:hover {
            background-color: white !important;
          }
        }
      }
    }
  }
}


.portlet_djelatnici {
  .fullscreen {
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: none;
    }
  }
  .mobile {
    @media (min-width: map-get($grid-breakpoints, md)) {
      display:none;
    }
  }

}
.accessibility {
  height: 40px;
}

#accessibility_opener {
  display: inline-block;
  img {
    display: none
  }
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  height: 28px;
  width: 28px;
  position: relative;
  top: -18px;
  background: url('../../../icons/accessibility.png');
  background-size: contain;
  //position: absolute;
}


.accessibility_dialog {
  @extend .shadow;
  max-width: 320px;
  width: 320px !important;
  background: white;
  padding: 15px;
  border: none;
  font-family: inherit;
  z-index: 9999;
  .ui-dialog-title{
    text-transform: uppercase;
  }
  .ui-dialog-titlebar {
    border: none !important;
  }
  .ui-dialog-titlebar-close {
    @extend .rounded;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    border: none;
    background: theme-color("primary") url('../../../icons/close.png') center no-repeat;
    -webkit-background-size: 10px;
    background-size: 10px;
  }
  #accessibility_links {
    max-width: 280px;
    .textsize {
      .gornjilinkoviboxVelicina {
        padding: 10px;
        font-weight: 800;
      }
      .select_size_0 {
        font-size: 11px !important;
      }
      .select_size_1 {
        font-size: 13px !important;
      }
      .select_size_2 {
        font-size: 15px !important;
      }
    }
    .highcontrast {
      .highcontrast_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
    .simplelayout {
      .simplelayout_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
  }
}


//Accessibility text size:
body.textsize-big {
  font-size: 20px;

  .accessibility_links, .ui-dialog-title {
    font-size: 16px !important;
    }
  .login_icon .login_link .fa-unlock-alt {
    font-size: 16px;
  }

  .top_link_row > .top_link{
    .fa {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
  }

  #cms_area_horizontal_nav .portlet_nav a {
    font-size: 20px;
  }
  #cms_area_right, #cms_area_left .portlet_nav .nav-element {
    .nav_link {
      font-size: 22px !important;
      padding: 8px;
    }
    li > ul li {
      padding-top: 0;
    }
    ul > li > a {
      font-size: 22px !important;
      padding: 8px;
    }
  }

  .cms_module_title {
    font-size: 24px;
  }

  .portlet_news {
    .news_article_title h1 {
      .news_title_truncateable {
        font-size: 24px !important;
      }
    }
  }
  .sidepanel .language span.w3c-lang-name {
    font-size: 16px;
    padding: 4px;
  }
  .sidepanel .login a {
    font-size: 20px;
    top: 6px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption h1 {
    font-size: 56px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p {
    font-size: 30px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p a{
    font-size: 30px !important;
  }

  #cms_area_middle .portlet_news_show_headlines h5, #cms_area_onecol_white .portlet_news_show_headlines h5 {
    font-size: 1.70rem;
  }

  #cms_area_white_left .portlet_content h2 {
    font-size: 2rem;
  }
  .fixed_header .fixed_nav div.faculty_name {
    font-size:20px;
  }
  .fixed_header {
    font-size: 18px;
  }

  h5 {
    font-size: 1.7rem;
  }
  .news_show_headlines_render_articles .card-body {
    font-size: 20px;
  }

  footer, footer .social {
    font-size: 18px;
  }
  footer h4 {
    font-size: 20px;
  }
  nav.main_nav li {
    font-size: 18px;
  }

  nav.main_nav .submenu ul .nav-link {
    font-size: 16px;
  }
}//end of .textsize-big
//END accessibility text size


//accessibility simplelayout
body.simplelayout {
  .carousel {
    display: none;
  }
  #cms_area_middle_right {
    .portlet_news_show_headlines {
      script {
        display: none;
      }
    }
  }
  .col-lg-4 {
    flex: unset;
    max-width: 100%;
    width: 100%;
  }

  .col-xl-3.col-md-6.col-sm-12.news_show_headlines_article {
    width: 100%;
    flex: unset;
    max-width: 100%;
  }
}
//END accessibility simplelayout

//accessibility contrast
body.highcontrast-normal {
  color: black !important;
  a {
    color: darken($primary, 50%);
  }
  .header_columns {
    color: black;
  }
  .top_link_row > .top_link a {
    color: black;
  }
  .accessibility_dialog {
    color: black;
    .ui-dialog-title{
      color: black;
    }
    .ui-dialog-titlebar-close {
      color: black;
    }
  }
  .horizontal-nav-row {
    color: black !important;
  }
  .portlet_nav {
    color: black !important;
    .nav > li > a {
      color: black !important;
    }
    .nav > li > ul > li a {
      color: black !important;
    }
  }
  .header-search_input {
    border: 1px solid $border-color !important;
    color: black;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: black !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: black !important;
      .truncate-to-visible {
        color: black !important;
      }
    }
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: black !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: black !important;
    font-weight: 800;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: black !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: black !important;
  }
  #footer_container {
    background-color: $border-color;
  }
  #footer_container #footer_header {
    color: black !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: black !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: black !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: black !important;
  }
  .mega-dropdown-menu {
    background: white !important;
  }
  .search-button, .lang_link {
    color: black !important;
  }
  .div_login img, .hamburger img {
   filter: invert(100%);
  }
  .mm-listview>li>a, .mm-listview>li>span {
    color: black !important;
  }
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:active, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:focus, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu  a:hover, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:visited {
     color: black !important;
  }
  .header .header-icons .search-separator .search-container input {
     border: 1px solid black;
     background-color: white !important;
     color: black !important;
  }

  .news_show_headlines_render_articles .card-footer {
    color: #000 !important;
  }
  footer a {
    color: #ffd037;
  }
  .fixed_nav {
    background: black !important;
    color: white !important;
    a {
      color: white !important;
    }
  }
}
//END accessibility contrast


//accessibility inverted contrast
body.highcontrast-inverted {
  color: white !important;
  background-color: #777;
  a {
    color:  white !important;
  }
  .popup_menu {
    background-color: #777 !important;
  }
  .portlet_admin_traka {
    .dropdown-menu {
      background-color: #777 !important;
      > li > a {
        background-color: #777 !important;
      }
    }
  }
  .logo_div {
    img.normal {
      display: none;
    }
    img.white {
      display: block;
    }
  }
  .header_columns {
    color: white !important;
  }
  .fixed_header {
    background-color: #333 !important;
  }
  #area_onecol_white, #area_onecol_silver, .twocol_side {
    background-color: #777;
  }
  .news_show_headlines_render_articles .card {
    background-color: #333;
  }
  .above_header .header_logo {
    filter: invert(1);
  }
  .sidepanel {
    background-color: #333 !important;
  }
  .sidepanel .col-12.col-md-12.col-lg-8 .bg-light, .sidepanel .col-12.col-md-12.col-lg-4 {
    background-color: #333 !important;
  }
  .sidepanel .language .current_lang span.w3c-lang-name, .sidepanel .language span.w3c-lang-name {
    color: white !important;
    border: 1px solid white !important;
  }
  .top_link_row > .top_link a {
    color: white !important;
  }
  .ui-widget-content {
    background-color: #777 !important;
  }
  .accessibility_dialog {
    span {
      color: white !important;
      font-weight: 800;
    }
    color: white !important;
    .ui-dialog-title{
      color: white !important;
    }
    .ui-dialog-titlebar-close {
      //color: white !important;
    }
  }
  .horizontal-nav-row {
    color: white !important;
  }
  .portlet_nav {
    color: white !important;
    background-color: #333;

    .nav > li > a {
      color: white !important;
    }
    .nav > li > ul > li a {
      color: white !important;
    }
    .nav {
      background-color: transparent !important;
    }
  }
  .nav ul li, .nav ul li:hover {
    background-color: #333 !important;
  }

  #cms_area_left .portlet_nav .nav_link:hover, #cms_area_left .portlet_nav .collapse-link:hover,  #cms_area_left .portlet_nav .nav-element .collapse-link:hover, #cms_area_left .portlet_nav .nav-element:hover {
    background-color: #333 !important;
  }
  div.repository .open, div.repository .closed {
    background-color: #333;
  }
  div.repository image {
    filter: invert(1);
  }
  div.repository .closed div.arrow, div.repository div.open div.arrow {
    color: white;
  }
  div.portlet_repository div.content {
    color: lightgrey;
  }
  #v1tooltip_div {
    background-color: #333;
  }
  .portlet_subscription .subscicons, .portlet_subscription .subs_module {
    color: white !important;
  }
  .portlet_predmet_info .col-md-3 p, .portlet_predmet_info .opterecenje_info, .portlet_predmet_info div em {
    color: white !important;
  }
  .staff_group, .portlet_djelatnici .person {
    color: white;
  }
  .header-search_input {
    border: 2px solid white !important;
    color: white !important;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: white !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: white !important;
      .truncate-to-visible {
        color: white !important;
      }
    }
  }
  .carousel-caption {
    color: white !important;
    .truncate-to-visible {
      color: white !important;
    }
  }
  .carousel {
    border-color:  #777 !important;
    .item {
      background-color: #777 !important;
    }
  }
  input, select {
    //border: 2px solid @brand-primary;
    //background-color: @brand-primary;
    color:  darken($border-color, 60%);
  }
  tr {
    background-color: darken($border-color, 60%)  !important;
    color: white;
  }
  tr:hover {
    background-color: darken($border-color, 60%)  !important;
    color: white !important;
    a, a:hover, a:active, a:focus {
      color: white !important;
      font-weight: 800 !important;
    }
  }
  tr:nth-of-type(2n+1) {
    background-color: darken($border-color, 30%)  !important;
    color: white;
  }
  tr:nth-of-type(2n+1):hover {
    background-color: darken($border-color, 30%)  !important;
    color: white;
    a, a:hover, a:active, a:focus {
      font-weight: 800 !important;
    }
  }
  .predmetispis_lighter {
    color: white;
  }
  .predmetispis_lighter:hover {
    font-weight: bold;
  }
  .item .carousel-caption .title a {
    color: white !important;
  }
  .item .carousel-caption h1 a {
    color: white !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: white !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: white !important;
    font-weight: 800;
    background-color: #333 !important;
  }
  .portlet_news_show_headlines .news_headlines .article .thumbnail {
    background-color: transparent !important;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: white !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: white !important;
  }
  #footer_container {
    background-color: transparent;
  }
  #footer_container #footer_header {
    color: white !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: white !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: white !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: white !important;
  }

  .gray, .white, .blue, .footer, .white-layer, .mega-dropdown-menu, .gradient, #mmenu_init {
      background-color: #333 !important;
  }
  .fixed_header .quilt_nav, .col-lg-4, .portlet_news .news_articles .card {
    background: #333;
  }

  nav.main_nav .submenu ul .submenu ul .links:hover, nav.main_nav .submenu ul .links:hover, .nav-link:focus, .nav-link:hover {
    background: #333;
  }
  .scrollable_content {
    background: #777;
  }
  #cms_area_left nav.main_nav {
    & > ul > li > .links:hover {
      background-color: #333;
    }
  }

  .cms_module .ui-tabs .ui-tabs-nav li.ui-state-active, .cms_module .ui-tabs .ui-tabs-nav li.ui-state-default, .cms_module .ui-tabs .ui-tabs-nav li.ui-state-active:hover, .cms_module .ui-tabs .ui-tabs-nav li.ui-state-default:hover {
    background-color: #333 !important;
  }

  .cms_module .ui-tabs .ui-tabs-nav li.ui-state-active {
    background: #333 !important;
  }
  .fixed_nav {
    background: black !important;
    color: white !important;
    a {
      color: white !important;
    }
  }

}
//END accessibility inverted contrast
