//VERZIJA 1 repozitorija:

.repository {
    clear:none;
    //font-size: 16px;
    color: #231f20;
}

.repository ul{
    list-style-type:none;
    padding:0;
    margin:0;
}

.repository ul li ul {
   //margin-left:15px;
    padding-left:15px;
}

.repository ul li{
    margin-left:0px;
}

.repository .entry{
    padding:1px;
    display:block;
    clear:both;
}

.repository .image{
    margin:0;
    padding-left:5px;
    float:left;
    max-height: 26px;
    max-width:26px;
    min-height: 26px;
    min-width:26px;
    background-size: 100% !important;
}

.repository .link {
    padding:3px 4px 4px 40px;
}

.repository .link:hover a {
    cursor:pointer;
    //text-decoration:underline;
    color: #231f20;

}

div.portlet_repository{
    //font-size:13px;
}
div.portlet_repository div.content{
   // font-size:13px;
    margin-left:1px;
    color: #9d0a0e; 
}
div.portlet_repository span.cms_info_about_authortime{
    font-size:10px;
    color: #aaa;
}
div.repository div.image{
    margin-top:4px;
}
div.repository div.head{

}
div.repository .closed {
    background-color: white;
}
div.repository .closed div.arrow{
    display:block;
    position:relative;
    width:12px;
    height:12px;
    top:12px;
    left:7px;
    cursor:pointer;

    div.arrow::after {
        content: "+";
        font-size: 20px;
    }
}
div.repository .closed div.arrow::after {
    content: "+";
    font-size: 20px;
}
div.repository .open {
    background-color: #f7f7f7;
}
div.repository .open div.arrow{
    display:block;
    position:relative;
    width:12px;
    height:12px;
    top:12px;
    left:7px;
    cursor:pointer;
}

div.repository .open div.arrow::after {
    content: "-";
    font-size: 20px;
}
div.repository div.open div.arrow{
    background-position: bottom;
}

.repository a {
    text-decoration:none;
}
.repository .content:after {
    clear:both;
}

.side .portlet_repository .repository{
    font-size: 14px;
    .link .cms_info_description, .link .cms_info_about_authortime{
        display:none;
    }
}