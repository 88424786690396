
html.logged-in {
  a:target {
    margin-top: -50px;
    padding-top: 50px;
  }
}
a:target {
  margin-top: -10px;
  padding-top: 10px;
}

.cms_module_title_hidden {display:none}

.cms_module_title {
  color: theme-color("primary");
}

/**** Header stuff ****/

.current_lang { display:none }
.w3c-lang-name {display: none}


html.logged-in, html.logged-in-view-as {
  //margin-top: @navbar-height;
  //margin-top: $navbar-brand-height + 2*$navbar-brand-padding-y + 2*$navbar-padding-y;
  margin-top: $nav-link-height + 2*$navbar-padding-y;
}

div.plike {
  //margin-bottom: (floor($line-height-computed / 2));
  margin-bottom: (floor($font-size-base / 2));
}
div.plike_half {
  //margin-bottom: (floor($line-height-computed / 4));
  margin-bottom: (floor($font-size-base / 4));
}

/**** Portlet Carousel ****/



.seemore_head2, .seemore_head1 {
  color: $link-color;
}


.admin_link {

  /*    .btn;
      .btn-warning;
      .btn-xs;*/

  .fa, .far, .fas, .fab, .fal {
    font-weight: 900 !important;
  }
}

.admin_link.fa, .admin_link.far, .admin_link.fas, .admin_link.fab, .admin_link.fal {
  font-weight: 900 !important;
}

.cms_link {
  //.btn; .btn-default; .btn-xs;
}

.bootstrap-hidden {
  display: none;
}

.navbar-default {
  background-color: theme-color("primary");
}



.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 60px; overflow: hidden;
  iframe,object,embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


table {
  /*    .table;
      .table-striped;
      .table-hover;*/
}

/* jQuery overrides: */
.ui-widget-content a {
  color: $link-color;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: transparent;
}
.ui-tabs-nav li.ui-state-active a {
  border-top: 2px solid theme-color("primary");
}
.ui-tabs-nav li.ui-state-active a {
  border-bottom: 3px solid white;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-bottom: -3px;
  max-height: 68px; /* bugfix da active ne bude viši od ostalih TODO: pametnije to rješit*/
}
.ui-tabs-nav li.ui-state-hover a, .ui-tabs-nav li.ui-state-default a {
  //color: $gray-light;
  color: $gray-200;
  font-weight: normal;
}
.ui-tabs-nav li.ui-state-active a {
  color: theme-color("primary");
  font-weight: bold;
}
/* end jQuery overrides */


.text-small {
  font-size: $font-size-sm;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}


.vertical_align_middle {
  vertical-align: middle;
}

input [type="text"] {
  //    .form-control;
}


.portlet_imenik {
  .person-heading-row {
    font-weight: bold;
    padding: 10px 5px;
  }
  .person-row {
    padding: 10px 5px;
    //broder-bottom: 1px dotted $gray-light;
    broder-bottom: 1px dotted $gray-200;
  }
}

.portlet_saml_login {
  .welcome-message {
    text-align: left;
  }

  .logout-button {
    //     .btn; .btn-primary; .btn-xs;
  }
}

.ui-widget {
  font-size: $font-size-base;
}


#lightbox_head {
  .button.right {
    // .btn; .btn-warning; .btn-sm;
  }
}

.text-strong {
  font-weight: bold;
}

.paging-form-control {
  //.form-control;
  width: auto;
}

ul.dbdocument-upload-image-list {
  list-style-type: none !important;
}
